import React from "react";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      light: "#39677c",
      main: "#08415c",
      dark: "#052d40",
    },
    secondary: {
      light: "#fb9b6f",
      main: "#fa824c",
      dark: "#af5b35",
    },
  },
});

interface Props {
  children: any;
}

const Theme = ({ children }: Props) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
