import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type ApplyTemplateDto = {
  templateId: Scalars['ID'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type Auth = {
  __typename?: 'Auth';
  access_token: Scalars['String'];
  user: User;
};

export type Business = {
  __typename?: 'Business';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressLevel1?: Maybe<Scalars['String']>;
  addressLevel2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  professions: Array<Profession>;
  employees: Array<Employee>;
};

export type CreateEmployeeDto = {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  professionId: Scalars['ID'];
  color: Scalars['String'];
  contractHours?: Maybe<Scalars['Float']>;
};

export type CreateEventDto = {
  id: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  employeeId: Scalars['ID'];
};

export type CreateHolidayDto = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  employeeId: Scalars['ID'];
};

export type CreateProfessionDto = {
  name: Scalars['String'];
  color: Scalars['String'];
  /** Default to the logged-in user business */
  businessId?: Maybe<Scalars['String']>;
};

export type CreateTemplateDto = {
  name: Scalars['String'];
  events: Array<CreateTemplateEvent>;
};

export type CreateTemplateEvent = {
  /** Representing hours:minutes */
  start: Scalars['String'];
  /** Representing hours:minutes */
  end: Scalars['String'];
  day: Day;
  employeeId: Scalars['ID'];
};

export type CreateUserDto = {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};


export enum Day {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export type EditProfessionDto = {
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
};

export type Employee = {
  __typename?: 'Employee';
  id: Scalars['ID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  profession: Profession;
  color: Scalars['String'];
  order: Scalars['Int'];
  contractHours: Scalars['Int'];
  business: Business;
  hours: Scalars['Float'];
  holidays: Array<Holiday>;
};


export type EmployeeHoursArgs = {
  inputs: HoursDto;
};


export type EmployeeHolidaysArgs = {
  inputs: HolidaysDto;
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  start: Scalars['DateTime'];
  duration: Scalars['Float'];
  end: Scalars['DateTime'];
  employee: Employee;
};

export type FindEventsDto = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  employeeId?: Maybe<Scalars['ID']>;
};

export type Holiday = {
  __typename?: 'Holiday';
  id: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  employeeId: Scalars['ID'];
};

export type HolidaysDto = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type HoursDto = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type LoginDto = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  updateMyBusiness: Business;
  createUser: User;
  updateShift: Shift;
  createProfession: Profession;
  editProfession: Profession;
  removeProfession: Scalars['Boolean'];
  createEmployee: Employee;
  removeEmployee: Employee;
  updateEmployee: Employee;
  updateEmployeesOrder: Array<Employee>;
  createEvent: Event;
  removeEvent: Event;
  removeEvents: Array<Event>;
  createHoliday: Holiday;
  updateHoliday: Holiday;
  deleteHoliday: Holiday;
  login: Auth;
  register: Auth;
  createTemplate: Template;
  deleteTemplate: Template;
  updateTemplate: Template;
  applyTemplate: Scalars['Boolean'];
};


export type MutationUpdateMyBusinessArgs = {
  inputs: UpdateBusinessDto;
};


export type MutationCreateUserArgs = {
  inputs: CreateUserDto;
};


export type MutationUpdateShiftArgs = {
  inputs: UpdateShiftDto;
};


export type MutationCreateProfessionArgs = {
  inputs: CreateProfessionDto;
};


export type MutationEditProfessionArgs = {
  inputs: EditProfessionDto;
};


export type MutationRemoveProfessionArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEmployeeArgs = {
  inputs: CreateEmployeeDto;
};


export type MutationRemoveEmployeeArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateEmployeeArgs = {
  inputs: UpdateEmployeDto;
};


export type MutationUpdateEmployeesOrderArgs = {
  employeesId: Array<Scalars['ID']>;
};


export type MutationCreateEventArgs = {
  inputs: CreateEventDto;
};


export type MutationRemoveEventArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveEventsArgs = {
  inputs: FindEventsDto;
};


export type MutationCreateHolidayArgs = {
  inputs: CreateHolidayDto;
};


export type MutationUpdateHolidayArgs = {
  inputs: UpdateHolidayDto;
};


export type MutationDeleteHolidayArgs = {
  id: Scalars['ID'];
};


export type MutationLoginArgs = {
  inputs: LoginDto;
};


export type MutationRegisterArgs = {
  inputs: RegisterDto;
};


export type MutationCreateTemplateArgs = {
  inputs: CreateTemplateDto;
};


export type MutationDeleteTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateTemplateArgs = {
  inputs: UpdateTemplateDto;
};


export type MutationApplyTemplateArgs = {
  inputs: ApplyTemplateDto;
};

export type Profession = {
  __typename?: 'Profession';
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  businesses: Array<Business>;
  business: Business;
  myBusiness: Business;
  me: User;
  shifts: Array<Shift>;
  professions: Array<Profession>;
  employees: Array<Employee>;
  employee: Employee;
  events: Array<Event>;
  holidays: Holiday;
  templates: Array<Template>;
};


export type QueryMyBusinessArgs = {
  ownerId: Scalars['String'];
};


export type QueryShiftsArgs = {
  day?: Maybe<Scalars['String']>;
};


export type QueryEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryEventsArgs = {
  data: FindEventsDto;
};

export type RegisterDto = {
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  password: Scalars['String'];
};

export enum Role {
  Admin = 'ADMIN',
  Owner = 'OWNER',
  Manager = 'MANAGER',
  Employee = 'EMPLOYEE',
  ScheduleUpdate = 'SCHEDULE_UPDATE',
  EmployeeUpdate = 'EMPLOYEE_UPDATE',
  EmployeeCreate = 'EMPLOYEE_CREATE'
}

export type Shift = {
  __typename?: 'Shift';
  id: Scalars['ID'];
  /** Representing hours:minutes */
  start?: Maybe<Scalars['String']>;
  /** Representing hours:minutes */
  end?: Maybe<Scalars['String']>;
  day: Day;
};

export type Template = {
  __typename?: 'Template';
  id: Scalars['ID'];
  name: Scalars['String'];
  events: Array<TemplateEvent>;
};

export type TemplateEvent = {
  __typename?: 'TemplateEvent';
  /** Representing hours:minutes */
  start: Scalars['String'];
  /** Representing hours:minutes */
  end: Scalars['String'];
  day: Day;
  employeeId: Scalars['ID'];
};

export type UpdateBusinessDto = {
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressLevel1?: Maybe<Scalars['String']>;
  addressLevel2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type UpdateEmployeDto = {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  professionId: Scalars['ID'];
  color: Scalars['String'];
  contractHours?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
};

export type UpdateHolidayDto = {
  id: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type UpdateShiftDto = {
  id: Scalars['ID'];
  /** Representing hours:minutes */
  start?: Maybe<Scalars['String']>;
  /** Representing hours:minutes */
  end?: Maybe<Scalars['String']>;
};

export type UpdateTemplateDto = {
  name?: Maybe<Scalars['String']>;
  events?: Maybe<Array<CreateTemplateEvent>>;
  id: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  roles: Array<Role>;
  business?: Maybe<Business>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UpdateMyBusinessMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressLevel2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
}>;


export type UpdateMyBusinessMutation = (
  { __typename?: 'Mutation' }
  & { updateMyBusiness: (
    { __typename?: 'Business' }
    & Pick<Business, 'id' | 'name' | 'address1' | 'address2' | 'addressLevel2' | 'postalCode'>
  ) }
);

export type GetBusinessQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessQuery = (
  { __typename?: 'Query' }
  & { business: (
    { __typename?: 'Business' }
    & Pick<Business, 'id' | 'name' | 'address1' | 'address2' | 'addressLevel2' | 'postalCode'>
  ) }
);

export type GetAllProfessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProfessionsQuery = (
  { __typename?: 'Query' }
  & { professions: Array<(
    { __typename?: 'Profession' }
    & Pick<Profession, 'name' | 'id'>
  )> }
);

export type DeleteEmployeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteEmployeMutation = (
  { __typename?: 'Mutation' }
  & { removeEmployee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  ) }
);

export type CreateEmployeeMutationVariables = Exact<{
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  professionId: Scalars['ID'];
  color: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  contractHours?: Maybe<Scalars['Float']>;
}>;


export type CreateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { createEmployee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  ) }
);

export type UpdateEmployeeMutationVariables = Exact<{
  id: Scalars['ID'];
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  professionId: Scalars['ID'];
  color: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  contractHours?: Maybe<Scalars['Float']>;
}>;


export type UpdateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  ) }
);

export type FindEmployeeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FindEmployeeQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'lastname' | 'firstname' | 'photo' | 'email' | 'contractHours' | 'color'>
    & { profession: (
      { __typename?: 'Profession' }
      & Pick<Profession, 'id'>
    ) }
  ) }
);

export type EmployeeHolidayQueryVariables = Exact<{
  id: Scalars['ID'];
  today?: Maybe<Scalars['DateTime']>;
}>;


export type EmployeeHolidayQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename: 'Employee' }
    & Pick<Employee, 'id'>
    & { upcomingHoliday: Array<(
      { __typename: 'Holiday' }
      & Pick<Holiday, 'id' | 'start' | 'end'>
    )>, historyHoliday: Array<(
      { __typename: 'Holiday' }
      & Pick<Holiday, 'id' | 'start' | 'end'>
    )> }
  ) }
);

export type CreateHolidayMutationVariables = Exact<{
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  employeeId: Scalars['ID'];
}>;


export type CreateHolidayMutation = (
  { __typename?: 'Mutation' }
  & { createHoliday: (
    { __typename: 'Holiday' }
    & Pick<Holiday, 'id' | 'start' | 'end'>
  ) }
);

export type UpdateHolidayMutationVariables = Exact<{
  id: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;


export type UpdateHolidayMutation = (
  { __typename?: 'Mutation' }
  & { updateHoliday: (
    { __typename: 'Holiday' }
    & Pick<Holiday, 'id' | 'start' | 'end'>
  ) }
);

export type DeleteHolidayMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteHolidayMutation = (
  { __typename?: 'Mutation' }
  & { deleteHoliday: (
    { __typename?: 'Holiday' }
    & Pick<Holiday, 'id'>
  ) }
);

export type GetAllEmployeeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllEmployeeQuery = (
  { __typename?: 'Query' }
  & { employees: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'firstname' | 'lastname' | 'order' | 'color'>
    & { profession: (
      { __typename?: 'Profession' }
      & Pick<Profession, 'id' | 'name' | 'color'>
    ) }
  )> }
);

export type UpdateEmployeesOrderMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type UpdateEmployeesOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployeesOrder: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'Auth' }
    & Pick<Auth, 'access_token'>
  ) }
);

export type UpdateShiftMutationVariables = Exact<{
  id: Scalars['ID'];
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
}>;


export type UpdateShiftMutation = (
  { __typename?: 'Mutation' }
  & { updateShift: (
    { __typename?: 'Shift' }
    & Pick<Shift, 'id'>
  ) }
);

export type ShiftsQueryVariables = Exact<{
  day?: Maybe<Scalars['String']>;
}>;


export type ShiftsQuery = (
  { __typename?: 'Query' }
  & { shifts: Array<(
    { __typename: 'Shift' }
    & Pick<Shift, 'id' | 'start' | 'day' | 'end'>
  )> }
);

export type GetProfessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfessionsQuery = (
  { __typename?: 'Query' }
  & { professions: Array<(
    { __typename?: 'Profession' }
    & Pick<Profession, 'name' | 'id' | 'color'>
  )> }
);

export type RemoveProfessionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveProfessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeProfession'>
);

export type UpdateProfessionMutationVariables = Exact<{
  name: Scalars['String'];
  id: Scalars['ID'];
  color: Scalars['String'];
}>;


export type UpdateProfessionMutation = (
  { __typename?: 'Mutation' }
  & { editProfession: (
    { __typename?: 'Profession' }
    & Pick<Profession, 'id' | 'name'>
  ) }
);

export type CreateProfessionMutationVariables = Exact<{
  name: Scalars['String'];
  color: Scalars['String'];
}>;


export type CreateProfessionMutation = (
  { __typename?: 'Mutation' }
  & { createProfession: (
    { __typename?: 'Profession' }
    & Pick<Profession, 'id' | 'name'>
  ) }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type RegisterMutationVariables = Exact<{
  email: Scalars['String'];
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  password: Scalars['String'];
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { register: (
    { __typename?: 'Auth' }
    & Pick<Auth, 'access_token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'lastname' | 'firstname'>
    ) }
  ) }
);

export type FindEventsQueryVariables = Exact<{
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}>;


export type FindEventsQuery = (
  { __typename?: 'Query' }
  & { events: Array<(
    { __typename: 'Event' }
    & Pick<Event, 'id' | 'start' | 'duration' | 'end'>
    & { employee: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
    ) }
  )> }
);

export type CreateEventMutationVariables = Exact<{
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  employeeId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type CreateEventMutation = (
  { __typename?: 'Mutation' }
  & { createEvent: (
    { __typename: 'Event' }
    & Pick<Event, 'id' | 'start' | 'end' | 'duration'>
    & { employee: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
    ) }
  ) }
);

export type DeleteEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteEventMutation = (
  { __typename?: 'Mutation' }
  & { removeEvent: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'duration'>
    & { employee: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
    ) }
  ) }
);

export type DeleteEventsMutationVariables = Exact<{
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  employeeId?: Maybe<Scalars['ID']>;
}>;


export type DeleteEventsMutation = (
  { __typename?: 'Mutation' }
  & { removeEvents: Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )> }
);

export type GetAllEmployeeSchedulerQueryVariables = Exact<{
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}>;


export type GetAllEmployeeSchedulerQuery = (
  { __typename?: 'Query' }
  & { employees: Array<(
    { __typename: 'Employee' }
    & Pick<Employee, 'id' | 'color' | 'firstname' | 'lastname' | 'contractHours' | 'order' | 'hours'>
    & { holidays: Array<(
      { __typename?: 'Holiday' }
      & Pick<Holiday, 'start' | 'end'>
    )> }
  )> }
);

export type CreateTemplateMutationVariables = Exact<{
  name: Scalars['String'];
  events: Array<CreateTemplateEvent>;
}>;


export type CreateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createTemplate: (
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'name'>
  ) }
);

export type TemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type TemplatesQuery = (
  { __typename?: 'Query' }
  & { templates: Array<(
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'name'>
  )> }
);

export type ApplyTemplateMutationVariables = Exact<{
  templateId: Scalars['ID'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}>;


export type ApplyTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'applyTemplate'>
);

export type RenameTemplateMutationVariables = Exact<{
  name: Scalars['String'];
  id: Scalars['ID'];
}>;


export type RenameTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateTemplate: (
    { __typename?: 'Template' }
    & Pick<Template, 'id'>
  ) }
);

export type DeleteTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTemplateMutation = (
  { __typename?: 'Mutation' }
  & { deleteTemplate: (
    { __typename?: 'Template' }
    & Pick<Template, 'id'>
  ) }
);

export type WelcomeQueryVariables = Exact<{ [key: string]: never; }>;


export type WelcomeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstname'>
    & { business?: Maybe<(
      { __typename: 'Business' }
      & Pick<Business, 'id' | 'name'>
      & { professions: Array<(
        { __typename: 'Profession' }
        & Pick<Profession, 'id'>
      )>, employees: Array<(
        { __typename: 'Employee' }
        & Pick<Employee, 'id'>
      )> }
    )> }
  ) }
);


export const UpdateMyBusinessDocument = gql`
    mutation updateMyBusiness($name: String, $address1: String, $address2: String, $addressLevel2: String, $postalCode: String) {
  updateMyBusiness(inputs: {name: $name, address1: $address1, address2: $address2, addressLevel2: $addressLevel2, postalCode: $postalCode}) {
    id
    name
    address1
    address2
    addressLevel2
    postalCode
  }
}
    `;
export type UpdateMyBusinessMutationFn = ApolloReactCommon.MutationFunction<UpdateMyBusinessMutation, UpdateMyBusinessMutationVariables>;

/**
 * __useUpdateMyBusinessMutation__
 *
 * To run a mutation, you first call `useUpdateMyBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyBusinessMutation, { data, loading, error }] = useUpdateMyBusinessMutation({
 *   variables: {
 *      name: // value for 'name'
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      addressLevel2: // value for 'addressLevel2'
 *      postalCode: // value for 'postalCode'
 *   },
 * });
 */
export function useUpdateMyBusinessMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMyBusinessMutation, UpdateMyBusinessMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMyBusinessMutation, UpdateMyBusinessMutationVariables>(UpdateMyBusinessDocument, baseOptions);
      }
export type UpdateMyBusinessMutationHookResult = ReturnType<typeof useUpdateMyBusinessMutation>;
export type UpdateMyBusinessMutationResult = ApolloReactCommon.MutationResult<UpdateMyBusinessMutation>;
export type UpdateMyBusinessMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMyBusinessMutation, UpdateMyBusinessMutationVariables>;
export const GetBusinessDocument = gql`
    query getBusiness {
  business {
    id
    name
    address1
    address2
    addressLevel2
    postalCode
  }
}
    `;

/**
 * __useGetBusinessQuery__
 *
 * To run a query within a React component, call `useGetBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBusinessQuery, GetBusinessQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBusinessQuery, GetBusinessQueryVariables>(GetBusinessDocument, baseOptions);
      }
export function useGetBusinessLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBusinessQuery, GetBusinessQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBusinessQuery, GetBusinessQueryVariables>(GetBusinessDocument, baseOptions);
        }
export type GetBusinessQueryHookResult = ReturnType<typeof useGetBusinessQuery>;
export type GetBusinessLazyQueryHookResult = ReturnType<typeof useGetBusinessLazyQuery>;
export type GetBusinessQueryResult = ApolloReactCommon.QueryResult<GetBusinessQuery, GetBusinessQueryVariables>;
export const GetAllProfessionsDocument = gql`
    query getAllProfessions {
  professions {
    name
    id
  }
}
    `;

/**
 * __useGetAllProfessionsQuery__
 *
 * To run a query within a React component, call `useGetAllProfessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProfessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProfessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProfessionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllProfessionsQuery, GetAllProfessionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllProfessionsQuery, GetAllProfessionsQueryVariables>(GetAllProfessionsDocument, baseOptions);
      }
export function useGetAllProfessionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllProfessionsQuery, GetAllProfessionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllProfessionsQuery, GetAllProfessionsQueryVariables>(GetAllProfessionsDocument, baseOptions);
        }
export type GetAllProfessionsQueryHookResult = ReturnType<typeof useGetAllProfessionsQuery>;
export type GetAllProfessionsLazyQueryHookResult = ReturnType<typeof useGetAllProfessionsLazyQuery>;
export type GetAllProfessionsQueryResult = ApolloReactCommon.QueryResult<GetAllProfessionsQuery, GetAllProfessionsQueryVariables>;
export const DeleteEmployeDocument = gql`
    mutation deleteEmploye($id: ID!) {
  removeEmployee(id: $id) {
    id
  }
}
    `;
export type DeleteEmployeMutationFn = ApolloReactCommon.MutationFunction<DeleteEmployeMutation, DeleteEmployeMutationVariables>;

/**
 * __useDeleteEmployeMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeMutation, { data, loading, error }] = useDeleteEmployeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmployeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEmployeMutation, DeleteEmployeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEmployeMutation, DeleteEmployeMutationVariables>(DeleteEmployeDocument, baseOptions);
      }
export type DeleteEmployeMutationHookResult = ReturnType<typeof useDeleteEmployeMutation>;
export type DeleteEmployeMutationResult = ApolloReactCommon.MutationResult<DeleteEmployeMutation>;
export type DeleteEmployeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEmployeMutation, DeleteEmployeMutationVariables>;
export const CreateEmployeeDocument = gql`
    mutation createEmployee($lastname: String!, $firstname: String!, $photo: String, $professionId: ID!, $color: String!, $email: String, $contractHours: Float) {
  createEmployee(inputs: {photo: $photo, lastname: $lastname, firstname: $firstname, professionId: $professionId, color: $color, email: $email, contractHours: $contractHours}) {
    id
  }
}
    `;
export type CreateEmployeeMutationFn = ApolloReactCommon.MutationFunction<CreateEmployeeMutation, CreateEmployeeMutationVariables>;

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      lastname: // value for 'lastname'
 *      firstname: // value for 'firstname'
 *      photo: // value for 'photo'
 *      professionId: // value for 'professionId'
 *      color: // value for 'color'
 *      email: // value for 'email'
 *      contractHours: // value for 'contractHours'
 *   },
 * });
 */
export function useCreateEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>(CreateEmployeeDocument, baseOptions);
      }
export type CreateEmployeeMutationHookResult = ReturnType<typeof useCreateEmployeeMutation>;
export type CreateEmployeeMutationResult = ApolloReactCommon.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation updateEmployee($id: ID!, $lastname: String!, $firstname: String!, $photo: String, $professionId: ID!, $color: String!, $email: String, $contractHours: Float) {
  updateEmployee(inputs: {id: $id, lastname: $lastname, firstname: $firstname, photo: $photo, professionId: $professionId, color: $color, email: $email, contractHours: $contractHours}) {
    id
  }
}
    `;
export type UpdateEmployeeMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      lastname: // value for 'lastname'
 *      firstname: // value for 'firstname'
 *      photo: // value for 'photo'
 *      professionId: // value for 'professionId'
 *      color: // value for 'color'
 *      email: // value for 'email'
 *      contractHours: // value for 'contractHours'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, baseOptions);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const FindEmployeeDocument = gql`
    query findEmployee($id: ID!) {
  employee(id: $id) {
    id
    lastname
    firstname
    photo
    email
    contractHours
    profession {
      id
    }
    color
  }
}
    `;

/**
 * __useFindEmployeeQuery__
 *
 * To run a query within a React component, call `useFindEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEmployeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindEmployeeQuery, FindEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<FindEmployeeQuery, FindEmployeeQueryVariables>(FindEmployeeDocument, baseOptions);
      }
export function useFindEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindEmployeeQuery, FindEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindEmployeeQuery, FindEmployeeQueryVariables>(FindEmployeeDocument, baseOptions);
        }
export type FindEmployeeQueryHookResult = ReturnType<typeof useFindEmployeeQuery>;
export type FindEmployeeLazyQueryHookResult = ReturnType<typeof useFindEmployeeLazyQuery>;
export type FindEmployeeQueryResult = ApolloReactCommon.QueryResult<FindEmployeeQuery, FindEmployeeQueryVariables>;
export const EmployeeHolidayDocument = gql`
    query employeeHoliday($id: ID!, $today: DateTime) {
  employee(id: $id) {
    __typename
    id
    upcomingHoliday: holidays(inputs: {from: $today}) {
      __typename
      id
      start
      end
    }
    historyHoliday: holidays(inputs: {to: $today}) {
      __typename
      id
      start
      end
    }
  }
}
    `;

/**
 * __useEmployeeHolidayQuery__
 *
 * To run a query within a React component, call `useEmployeeHolidayQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeHolidayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeHolidayQuery({
 *   variables: {
 *      id: // value for 'id'
 *      today: // value for 'today'
 *   },
 * });
 */
export function useEmployeeHolidayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeHolidayQuery, EmployeeHolidayQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeHolidayQuery, EmployeeHolidayQueryVariables>(EmployeeHolidayDocument, baseOptions);
      }
export function useEmployeeHolidayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeHolidayQuery, EmployeeHolidayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeHolidayQuery, EmployeeHolidayQueryVariables>(EmployeeHolidayDocument, baseOptions);
        }
export type EmployeeHolidayQueryHookResult = ReturnType<typeof useEmployeeHolidayQuery>;
export type EmployeeHolidayLazyQueryHookResult = ReturnType<typeof useEmployeeHolidayLazyQuery>;
export type EmployeeHolidayQueryResult = ApolloReactCommon.QueryResult<EmployeeHolidayQuery, EmployeeHolidayQueryVariables>;
export const CreateHolidayDocument = gql`
    mutation createHoliday($start: DateTime!, $end: DateTime!, $employeeId: ID!) {
  createHoliday(inputs: {start: $start, end: $end, employeeId: $employeeId}) {
    id
    __typename
    start
    end
  }
}
    `;
export type CreateHolidayMutationFn = ApolloReactCommon.MutationFunction<CreateHolidayMutation, CreateHolidayMutationVariables>;

/**
 * __useCreateHolidayMutation__
 *
 * To run a mutation, you first call `useCreateHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHolidayMutation, { data, loading, error }] = useCreateHolidayMutation({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useCreateHolidayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateHolidayMutation, CreateHolidayMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateHolidayMutation, CreateHolidayMutationVariables>(CreateHolidayDocument, baseOptions);
      }
export type CreateHolidayMutationHookResult = ReturnType<typeof useCreateHolidayMutation>;
export type CreateHolidayMutationResult = ApolloReactCommon.MutationResult<CreateHolidayMutation>;
export type CreateHolidayMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateHolidayMutation, CreateHolidayMutationVariables>;
export const UpdateHolidayDocument = gql`
    mutation updateHoliday($id: ID!, $start: DateTime!, $end: DateTime!) {
  updateHoliday(inputs: {start: $start, end: $end, id: $id}) {
    id
    __typename
    start
    end
  }
}
    `;
export type UpdateHolidayMutationFn = ApolloReactCommon.MutationFunction<UpdateHolidayMutation, UpdateHolidayMutationVariables>;

/**
 * __useUpdateHolidayMutation__
 *
 * To run a mutation, you first call `useUpdateHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHolidayMutation, { data, loading, error }] = useUpdateHolidayMutation({
 *   variables: {
 *      id: // value for 'id'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useUpdateHolidayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateHolidayMutation, UpdateHolidayMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateHolidayMutation, UpdateHolidayMutationVariables>(UpdateHolidayDocument, baseOptions);
      }
export type UpdateHolidayMutationHookResult = ReturnType<typeof useUpdateHolidayMutation>;
export type UpdateHolidayMutationResult = ApolloReactCommon.MutationResult<UpdateHolidayMutation>;
export type UpdateHolidayMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateHolidayMutation, UpdateHolidayMutationVariables>;
export const DeleteHolidayDocument = gql`
    mutation deleteHoliday($id: ID!) {
  deleteHoliday(id: $id) {
    id
  }
}
    `;
export type DeleteHolidayMutationFn = ApolloReactCommon.MutationFunction<DeleteHolidayMutation, DeleteHolidayMutationVariables>;

/**
 * __useDeleteHolidayMutation__
 *
 * To run a mutation, you first call `useDeleteHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHolidayMutation, { data, loading, error }] = useDeleteHolidayMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHolidayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteHolidayMutation, DeleteHolidayMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteHolidayMutation, DeleteHolidayMutationVariables>(DeleteHolidayDocument, baseOptions);
      }
export type DeleteHolidayMutationHookResult = ReturnType<typeof useDeleteHolidayMutation>;
export type DeleteHolidayMutationResult = ApolloReactCommon.MutationResult<DeleteHolidayMutation>;
export type DeleteHolidayMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteHolidayMutation, DeleteHolidayMutationVariables>;
export const GetAllEmployeeDocument = gql`
    query getAllEmployee {
  employees {
    id
    firstname
    lastname
    order
    color
    profession {
      id
      name
      color
    }
  }
}
    `;

/**
 * __useGetAllEmployeeQuery__
 *
 * To run a query within a React component, call `useGetAllEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEmployeeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllEmployeeQuery, GetAllEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllEmployeeQuery, GetAllEmployeeQueryVariables>(GetAllEmployeeDocument, baseOptions);
      }
export function useGetAllEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllEmployeeQuery, GetAllEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllEmployeeQuery, GetAllEmployeeQueryVariables>(GetAllEmployeeDocument, baseOptions);
        }
export type GetAllEmployeeQueryHookResult = ReturnType<typeof useGetAllEmployeeQuery>;
export type GetAllEmployeeLazyQueryHookResult = ReturnType<typeof useGetAllEmployeeLazyQuery>;
export type GetAllEmployeeQueryResult = ApolloReactCommon.QueryResult<GetAllEmployeeQuery, GetAllEmployeeQueryVariables>;
export const UpdateEmployeesOrderDocument = gql`
    mutation updateEmployeesOrder($ids: [ID!]!) {
  updateEmployeesOrder(employeesId: $ids) {
    id
  }
}
    `;
export type UpdateEmployeesOrderMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeesOrderMutation, UpdateEmployeesOrderMutationVariables>;

/**
 * __useUpdateEmployeesOrderMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeesOrderMutation, { data, loading, error }] = useUpdateEmployeesOrderMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUpdateEmployeesOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmployeesOrderMutation, UpdateEmployeesOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmployeesOrderMutation, UpdateEmployeesOrderMutationVariables>(UpdateEmployeesOrderDocument, baseOptions);
      }
export type UpdateEmployeesOrderMutationHookResult = ReturnType<typeof useUpdateEmployeesOrderMutation>;
export type UpdateEmployeesOrderMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeesOrderMutation>;
export type UpdateEmployeesOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeesOrderMutation, UpdateEmployeesOrderMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(inputs: {email: $email, password: $password}) {
    access_token
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const UpdateShiftDocument = gql`
    mutation updateShift($id: ID!, $start: String, $end: String) {
  updateShift(inputs: {id: $id, start: $start, end: $end}) {
    id
  }
}
    `;
export type UpdateShiftMutationFn = ApolloReactCommon.MutationFunction<UpdateShiftMutation, UpdateShiftMutationVariables>;

/**
 * __useUpdateShiftMutation__
 *
 * To run a mutation, you first call `useUpdateShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftMutation, { data, loading, error }] = useUpdateShiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useUpdateShiftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateShiftMutation, UpdateShiftMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateShiftMutation, UpdateShiftMutationVariables>(UpdateShiftDocument, baseOptions);
      }
export type UpdateShiftMutationHookResult = ReturnType<typeof useUpdateShiftMutation>;
export type UpdateShiftMutationResult = ApolloReactCommon.MutationResult<UpdateShiftMutation>;
export type UpdateShiftMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateShiftMutation, UpdateShiftMutationVariables>;
export const ShiftsDocument = gql`
    query shifts($day: String) {
  shifts(day: $day) {
    id
    start
    day
    end
    __typename
  }
}
    `;

/**
 * __useShiftsQuery__
 *
 * To run a query within a React component, call `useShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftsQuery({
 *   variables: {
 *      day: // value for 'day'
 *   },
 * });
 */
export function useShiftsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShiftsQuery, ShiftsQueryVariables>) {
        return ApolloReactHooks.useQuery<ShiftsQuery, ShiftsQueryVariables>(ShiftsDocument, baseOptions);
      }
export function useShiftsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShiftsQuery, ShiftsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ShiftsQuery, ShiftsQueryVariables>(ShiftsDocument, baseOptions);
        }
export type ShiftsQueryHookResult = ReturnType<typeof useShiftsQuery>;
export type ShiftsLazyQueryHookResult = ReturnType<typeof useShiftsLazyQuery>;
export type ShiftsQueryResult = ApolloReactCommon.QueryResult<ShiftsQuery, ShiftsQueryVariables>;
export const GetProfessionsDocument = gql`
    query getProfessions {
  professions {
    name
    id
    color
  }
}
    `;

/**
 * __useGetProfessionsQuery__
 *
 * To run a query within a React component, call `useGetProfessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfessionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProfessionsQuery, GetProfessionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProfessionsQuery, GetProfessionsQueryVariables>(GetProfessionsDocument, baseOptions);
      }
export function useGetProfessionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProfessionsQuery, GetProfessionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProfessionsQuery, GetProfessionsQueryVariables>(GetProfessionsDocument, baseOptions);
        }
export type GetProfessionsQueryHookResult = ReturnType<typeof useGetProfessionsQuery>;
export type GetProfessionsLazyQueryHookResult = ReturnType<typeof useGetProfessionsLazyQuery>;
export type GetProfessionsQueryResult = ApolloReactCommon.QueryResult<GetProfessionsQuery, GetProfessionsQueryVariables>;
export const RemoveProfessionDocument = gql`
    mutation removeProfession($id: ID!) {
  removeProfession(id: $id)
}
    `;
export type RemoveProfessionMutationFn = ApolloReactCommon.MutationFunction<RemoveProfessionMutation, RemoveProfessionMutationVariables>;

/**
 * __useRemoveProfessionMutation__
 *
 * To run a mutation, you first call `useRemoveProfessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProfessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProfessionMutation, { data, loading, error }] = useRemoveProfessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveProfessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveProfessionMutation, RemoveProfessionMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveProfessionMutation, RemoveProfessionMutationVariables>(RemoveProfessionDocument, baseOptions);
      }
export type RemoveProfessionMutationHookResult = ReturnType<typeof useRemoveProfessionMutation>;
export type RemoveProfessionMutationResult = ApolloReactCommon.MutationResult<RemoveProfessionMutation>;
export type RemoveProfessionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveProfessionMutation, RemoveProfessionMutationVariables>;
export const UpdateProfessionDocument = gql`
    mutation updateProfession($name: String!, $id: ID!, $color: String!) {
  editProfession(inputs: {name: $name, id: $id, color: $color}) {
    id
    name
  }
}
    `;
export type UpdateProfessionMutationFn = ApolloReactCommon.MutationFunction<UpdateProfessionMutation, UpdateProfessionMutationVariables>;

/**
 * __useUpdateProfessionMutation__
 *
 * To run a mutation, you first call `useUpdateProfessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfessionMutation, { data, loading, error }] = useUpdateProfessionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useUpdateProfessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProfessionMutation, UpdateProfessionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProfessionMutation, UpdateProfessionMutationVariables>(UpdateProfessionDocument, baseOptions);
      }
export type UpdateProfessionMutationHookResult = ReturnType<typeof useUpdateProfessionMutation>;
export type UpdateProfessionMutationResult = ApolloReactCommon.MutationResult<UpdateProfessionMutation>;
export type UpdateProfessionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProfessionMutation, UpdateProfessionMutationVariables>;
export const CreateProfessionDocument = gql`
    mutation createProfession($name: String!, $color: String!) {
  createProfession(inputs: {name: $name, color: $color}) {
    id
    name
  }
}
    `;
export type CreateProfessionMutationFn = ApolloReactCommon.MutationFunction<CreateProfessionMutation, CreateProfessionMutationVariables>;

/**
 * __useCreateProfessionMutation__
 *
 * To run a mutation, you first call `useCreateProfessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfessionMutation, { data, loading, error }] = useCreateProfessionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useCreateProfessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProfessionMutation, CreateProfessionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProfessionMutation, CreateProfessionMutationVariables>(CreateProfessionDocument, baseOptions);
      }
export type CreateProfessionMutationHookResult = ReturnType<typeof useCreateProfessionMutation>;
export type CreateProfessionMutationResult = ApolloReactCommon.MutationResult<CreateProfessionMutation>;
export type CreateProfessionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProfessionMutation, CreateProfessionMutationVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  me {
    id
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = ApolloReactCommon.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const RegisterDocument = gql`
    mutation register($email: String!, $lastname: String!, $firstname: String!, $password: String!) {
  register(inputs: {email: $email, firstname: $firstname, lastname: $lastname, password: $password}) {
    access_token
    user {
      id
      email
      lastname
      firstname
    }
  }
}
    `;
export type RegisterMutationFn = ApolloReactCommon.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      lastname: // value for 'lastname'
 *      firstname: // value for 'firstname'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = ApolloReactCommon.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const FindEventsDocument = gql`
    query findEvents($from: DateTime!, $to: DateTime!) {
  events(data: {from: $from, to: $to}) {
    id
    __typename
    start
    duration
    end
    employee {
      id
    }
  }
}
    `;

/**
 * __useFindEventsQuery__
 *
 * To run a query within a React component, call `useFindEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEventsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useFindEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindEventsQuery, FindEventsQueryVariables>) {
        return ApolloReactHooks.useQuery<FindEventsQuery, FindEventsQueryVariables>(FindEventsDocument, baseOptions);
      }
export function useFindEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindEventsQuery, FindEventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindEventsQuery, FindEventsQueryVariables>(FindEventsDocument, baseOptions);
        }
export type FindEventsQueryHookResult = ReturnType<typeof useFindEventsQuery>;
export type FindEventsLazyQueryHookResult = ReturnType<typeof useFindEventsLazyQuery>;
export type FindEventsQueryResult = ApolloReactCommon.QueryResult<FindEventsQuery, FindEventsQueryVariables>;
export const CreateEventDocument = gql`
    mutation createEvent($start: DateTime!, $end: DateTime!, $employeeId: ID!, $id: ID!) {
  createEvent(inputs: {id: $id, start: $start, end: $end, employeeId: $employeeId}) {
    id
    __typename
    start
    end
    duration
    employee {
      id
    }
  }
}
    `;
export type CreateEventMutationFn = ApolloReactCommon.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      employeeId: // value for 'employeeId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, baseOptions);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = ApolloReactCommon.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const DeleteEventDocument = gql`
    mutation deleteEvent($id: ID!) {
  removeEvent(id: $id) {
    id
    employee {
      id
    }
    duration
  }
}
    `;
export type DeleteEventMutationFn = ApolloReactCommon.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, baseOptions);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = ApolloReactCommon.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const DeleteEventsDocument = gql`
    mutation deleteEvents($from: DateTime!, $to: DateTime!, $employeeId: ID) {
  removeEvents(inputs: {from: $from, to: $to, employeeId: $employeeId}) {
    id
  }
}
    `;
export type DeleteEventsMutationFn = ApolloReactCommon.MutationFunction<DeleteEventsMutation, DeleteEventsMutationVariables>;

/**
 * __useDeleteEventsMutation__
 *
 * To run a mutation, you first call `useDeleteEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventsMutation, { data, loading, error }] = useDeleteEventsMutation({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useDeleteEventsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEventsMutation, DeleteEventsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEventsMutation, DeleteEventsMutationVariables>(DeleteEventsDocument, baseOptions);
      }
export type DeleteEventsMutationHookResult = ReturnType<typeof useDeleteEventsMutation>;
export type DeleteEventsMutationResult = ApolloReactCommon.MutationResult<DeleteEventsMutation>;
export type DeleteEventsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEventsMutation, DeleteEventsMutationVariables>;
export const GetAllEmployeeSchedulerDocument = gql`
    query getAllEmployeeScheduler($from: DateTime!, $to: DateTime!) {
  employees {
    id
    __typename
    color
    firstname
    lastname
    contractHours
    order
    hours(inputs: {from: $from, to: $to})
    holidays(inputs: {from: $from, to: $to}) {
      start
      end
    }
  }
}
    `;

/**
 * __useGetAllEmployeeSchedulerQuery__
 *
 * To run a query within a React component, call `useGetAllEmployeeSchedulerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEmployeeSchedulerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEmployeeSchedulerQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetAllEmployeeSchedulerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllEmployeeSchedulerQuery, GetAllEmployeeSchedulerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllEmployeeSchedulerQuery, GetAllEmployeeSchedulerQueryVariables>(GetAllEmployeeSchedulerDocument, baseOptions);
      }
export function useGetAllEmployeeSchedulerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllEmployeeSchedulerQuery, GetAllEmployeeSchedulerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllEmployeeSchedulerQuery, GetAllEmployeeSchedulerQueryVariables>(GetAllEmployeeSchedulerDocument, baseOptions);
        }
export type GetAllEmployeeSchedulerQueryHookResult = ReturnType<typeof useGetAllEmployeeSchedulerQuery>;
export type GetAllEmployeeSchedulerLazyQueryHookResult = ReturnType<typeof useGetAllEmployeeSchedulerLazyQuery>;
export type GetAllEmployeeSchedulerQueryResult = ApolloReactCommon.QueryResult<GetAllEmployeeSchedulerQuery, GetAllEmployeeSchedulerQueryVariables>;
export const CreateTemplateDocument = gql`
    mutation createTemplate($name: String!, $events: [CreateTemplateEvent!]!) {
  createTemplate(inputs: {events: $events, name: $name}) {
    id
    name
  }
}
    `;
export type CreateTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateTemplateMutation, CreateTemplateMutationVariables>;

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      events: // value for 'events'
 *   },
 * });
 */
export function useCreateTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTemplateMutation, CreateTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(CreateTemplateDocument, baseOptions);
      }
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export type CreateTemplateMutationResult = ApolloReactCommon.MutationResult<CreateTemplateMutation>;
export type CreateTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTemplateMutation, CreateTemplateMutationVariables>;
export const TemplatesDocument = gql`
    query templates {
  templates {
    id
    name
  }
}
    `;

/**
 * __useTemplatesQuery__
 *
 * To run a query within a React component, call `useTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, baseOptions);
      }
export function useTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, baseOptions);
        }
export type TemplatesQueryHookResult = ReturnType<typeof useTemplatesQuery>;
export type TemplatesLazyQueryHookResult = ReturnType<typeof useTemplatesLazyQuery>;
export type TemplatesQueryResult = ApolloReactCommon.QueryResult<TemplatesQuery, TemplatesQueryVariables>;
export const ApplyTemplateDocument = gql`
    mutation applyTemplate($templateId: ID!, $from: DateTime!, $to: DateTime!) {
  applyTemplate(inputs: {templateId: $templateId, from: $from, to: $to})
}
    `;
export type ApplyTemplateMutationFn = ApolloReactCommon.MutationFunction<ApplyTemplateMutation, ApplyTemplateMutationVariables>;

/**
 * __useApplyTemplateMutation__
 *
 * To run a mutation, you first call `useApplyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyTemplateMutation, { data, loading, error }] = useApplyTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useApplyTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApplyTemplateMutation, ApplyTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<ApplyTemplateMutation, ApplyTemplateMutationVariables>(ApplyTemplateDocument, baseOptions);
      }
export type ApplyTemplateMutationHookResult = ReturnType<typeof useApplyTemplateMutation>;
export type ApplyTemplateMutationResult = ApolloReactCommon.MutationResult<ApplyTemplateMutation>;
export type ApplyTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<ApplyTemplateMutation, ApplyTemplateMutationVariables>;
export const RenameTemplateDocument = gql`
    mutation renameTemplate($name: String!, $id: ID!) {
  updateTemplate(inputs: {id: $id, name: $name}) {
    id
  }
}
    `;
export type RenameTemplateMutationFn = ApolloReactCommon.MutationFunction<RenameTemplateMutation, RenameTemplateMutationVariables>;

/**
 * __useRenameTemplateMutation__
 *
 * To run a mutation, you first call `useRenameTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameTemplateMutation, { data, loading, error }] = useRenameTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRenameTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenameTemplateMutation, RenameTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<RenameTemplateMutation, RenameTemplateMutationVariables>(RenameTemplateDocument, baseOptions);
      }
export type RenameTemplateMutationHookResult = ReturnType<typeof useRenameTemplateMutation>;
export type RenameTemplateMutationResult = ApolloReactCommon.MutationResult<RenameTemplateMutation>;
export type RenameTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<RenameTemplateMutation, RenameTemplateMutationVariables>;
export const DeleteTemplateDocument = gql`
    mutation deleteTemplate($id: ID!) {
  deleteTemplate(id: $id) {
    id
  }
}
    `;
export type DeleteTemplateMutationFn = ApolloReactCommon.MutationFunction<DeleteTemplateMutation, DeleteTemplateMutationVariables>;

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(DeleteTemplateDocument, baseOptions);
      }
export type DeleteTemplateMutationHookResult = ReturnType<typeof useDeleteTemplateMutation>;
export type DeleteTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export const WelcomeDocument = gql`
    query welcome {
  me {
    id
    __typename
    firstname
    business {
      id
      name
      __typename
      professions {
        id
        __typename
      }
      employees {
        __typename
        id
      }
    }
  }
}
    `;

/**
 * __useWelcomeQuery__
 *
 * To run a query within a React component, call `useWelcomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useWelcomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWelcomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useWelcomeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WelcomeQuery, WelcomeQueryVariables>) {
        return ApolloReactHooks.useQuery<WelcomeQuery, WelcomeQueryVariables>(WelcomeDocument, baseOptions);
      }
export function useWelcomeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WelcomeQuery, WelcomeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WelcomeQuery, WelcomeQueryVariables>(WelcomeDocument, baseOptions);
        }
export type WelcomeQueryHookResult = ReturnType<typeof useWelcomeQuery>;
export type WelcomeLazyQueryHookResult = ReturnType<typeof useWelcomeLazyQuery>;
export type WelcomeQueryResult = ApolloReactCommon.QueryResult<WelcomeQuery, WelcomeQueryVariables>;