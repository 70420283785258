import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";

declare global {
  interface Window {
    ga?: any;
    dataLayer?: any;
  }
}

Sentry.init({
  dsn: "https://b33fc962112749b4a6f1356747d2a404@sentry.io/100639",
  environment: process.env.NODE_ENV,
  attachStacktrace: true,
});

if (process.env.REACT_APP_GA_CODE) {
  window.dataLayer.push({ GA_CODE: process.env.REACT_APP_GA_CODE });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
