import React, { useEffect, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Container } from "@material-ui/core";
import logo from "../assets/logo.svg";
import Box from "@material-ui/core/Box";
import {
  FindEventsQuery,
  GetAllEmployeeSchedulerQuery,
  useFindEventsQuery,
  useGetAllEmployeeSchedulerQuery,
  useShiftsQuery,
} from "../graphql/operations";
import { useLocation } from "react-router";
import { fr } from "date-fns/locale";
import {
  getYear,
  eachDayOfInterval,
  format,
  isSameDay,
  getISOWeeksInYear,
  isWithinInterval,
  startOfDay,
  endOfDay,
} from "date-fns";

import Calendar from "../utils/calendar";
import { useReactToPrint } from "react-to-print";

const GlobalStyle = createGlobalStyle`
  @page {
    size: A4;
    margin: 0;
  }
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px;
  font-size: 12px;
`;

const LogoWrapper = styled(Box)`
  background-color: black !important;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
`;

const Logo = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.h1`
  font-size: 16px;
  font-weight: 500;
`;

const DayTitle = styled.h2`
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0;
  margin-top: 16px;
`;

const Day = styled(Box)`
  position: relative;
  break-inside: avoid-page;
  break-before: auto;
`;

type Employee = GetAllEmployeeSchedulerQuery["employees"][0];

function isWorking(date: Date) {
  return function (employee: Employee) {
    return !employee.holidays.reduce(
      (isOnHoliday, holiday) =>
        isOnHoliday ||
        isWithinInterval(date, {
          start: startOfDay(new Date(holiday.start)),
          end: endOfDay(new Date(holiday.end)),
        }),
      false
    );
  };
}

export default function SchedulerPrintable(): React.ReactElement | null {
  const page = useRef<HTMLDivElement>(null);
  const query = new URLSearchParams(useLocation().search);
  const from = query.get("from");
  const to = query.get("to");
  const handlePrint = useReactToPrint({
    content: () => page.current,
  });

  useEffect(() => {
    handlePrint && handlePrint();
  }, [handlePrint]);

  const { data: employeeData } = useGetAllEmployeeSchedulerQuery({
    variables: { from: from && new Date(from), to: to && new Date(to) },
  });
  const { data: eventsData } = useFindEventsQuery({
    variables: { from: from && new Date(from), to: to && new Date(to) },
    skip: !from || !to,
  });

  const { data: shiftData } = useShiftsQuery();

  if (!employeeData || !eventsData || !from || !to || !shiftData) return null;

  const calendar = new Calendar(shiftData.shifts);

  const dates = eachDayOfInterval({ start: new Date(from), end: new Date(to) });

  return (
    <Page ref={page}>
      <GlobalStyle />
      <Box display="flex" marginBottom={3} alignItems="center">
        <LogoWrapper width="150px" bgcolor="black" borderRadius={16}>
          <Logo src={logo} alt="Pharmille logo" />
        </LogoWrapper>
        <Box textAlign="right" flex={1}>
          <Title>
            Planning de la Semaine {getISOWeeksInYear(new Date(from))} (
            {getYear(new Date(from))})
          </Title>
        </Box>
      </Box>
      <Container maxWidth="md">
        {dates.map((day) => {
          if (!calendar.isOpenDay(day)) return null;
          const dayShifts = calendar.getDateShifts(day);
          if (dayShifts.length === 0) return null;
          const dayEvents = eventsData.events.filter((e) =>
            isSameDay(new Date(e.start), day)
          );

          return (
            calendar.isOpenDay(day) && (
              <Day display="flex" flexDirection="column" key={day.toString()}>
                <Box marginBottom={1}>
                  <DayTitle>
                    {format(day, "EEEE (dd MMMM)", { locale: fr })}
                  </DayTitle>
                </Box>
                <Box py={1} display="flex">
                  <Box flex={1} />
                  <Box flex={1} textAlign="center" fontWeight="bold">
                    Matinée
                  </Box>
                  <Box flex={1} textAlign="center" fontWeight="bold">
                    Après-midi
                  </Box>
                </Box>
                {employeeData.employees.map((employee) => {
                  const dayEventsEmployee = dayEvents.filter(
                    (e) => e.employee.id === employee.id
                  );

                  const eventsByShifts: FindEventsQuery["events"][] = dayEventsEmployee.reduce(
                    (byShifts, event) => {
                      byShifts[
                        calendar.isAfternoon(new Date(event.start)) ? 1 : 0
                      ].push(event);
                      return byShifts;
                    },
                    [
                      [] as FindEventsQuery["events"],
                      [] as FindEventsQuery["events"],
                    ]
                  );

                  return (
                    <Box py={1} display="flex" key={employee.id}>
                      <Box flex={1}>
                        {employee.firstname} {employee.lastname}
                      </Box>
                      {isWorking(day)(employee) ? (
                        eventsByShifts.map((shiftEvents, i) => {
                          if (shiftEvents.length === 0)
                            return (
                              <Box flex={1} textAlign="center" key={i}>
                                <span aria-label="Aucun horaire" role="img">
                                  🚫
                                </span>
                              </Box>
                            );

                          return (
                            <Box flex={1} textAlign="center" key={i}>
                              {shiftEvents
                                .map(
                                  (e) =>
                                    `${format(
                                      new Date(e.start),
                                      "HH:mm"
                                    )} - ${format(new Date(e.end), "HH:mm")}`
                                )
                                .join(", ")}
                            </Box>
                          );
                        })
                      ) : (
                        <Box textAlign="center" flex={2}>
                          En vacance
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Day>
            )
          );
        })}
      </Container>
    </Page>
  );
}
