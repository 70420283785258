import React from "react";
import { useLocation, Redirect } from "react-router";

import * as Sentry from "@sentry/browser";

import { useAppState } from "../AppState";
import { useGetCurrentUserQuery } from "../../graphql/operations";

interface Props {
  children: React.ReactElement;
}

const ProtectedComponent: React.FC<Props> = ({ children }) => {
  const { logout, isLoggedIn } = useAppState();
  const location = useLocation();
  const { data: meQuery, loading } = useGetCurrentUserQuery({
    fetchPolicy: "network-only",
    onError: () => {
      logout();
    },
  });

  if (loading) return null;

  // Sentry set user
  Sentry.configureScope((scope) => {
    if (meQuery && meQuery.me && meQuery.me.id) {
      scope.setUser({ id: meQuery.me.id });
    }
  });
  // Google Analytics set user
  if (meQuery && meQuery.me && meQuery.me.id) {
    window.dataLayer.push({ userId: meQuery.me.id });
  }

  return isLoggedIn ? (
    children
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: location },
      }}
    />
  );
};

export default ProtectedComponent;
