import { Day, Shift } from "../graphql/resolvers";
import { last, groupBy } from "lodash";
import { differenceInMinutes, isAfter, isEqual } from "date-fns";

export type TimeMatrix = { [key in Day]: Array<0 | 1> };

export default class Calendar {
  shifts: Shift[];
  step: number;

  constructor(shifts: Shift[], step = 15) {
    this.shifts = shifts;
    this.step = step;
  }

  static DAY: Day[] = [
    Day.Sunday,
    Day.Monday,
    Day.Tuesday,
    Day.Wednesday,
    Day.Thursday,
    Day.Friday,
    Day.Saturday,
  ];

  static getStringDay(day: number) {
    if (!Calendar.DAY[day]) return null;
    return Calendar.DAY[day];
  }

  static isShiftValid(shift: Shift) {
    const date = new Date();
    const start = Calendar.timeToDateConverter(date)(shift.start);
    const end = Calendar.timeToDateConverter(date)(shift.end);
    if (!start || !end) return false;
    return end.getTime() > start.getTime();
  }

  static timeToDateConverter(date: Date) {
    return function (time: string | null | undefined) {
      if (!time) return null;
      const [hours, minutes] = time.split(":");
      if (!hours || !minutes) return null;

      const result = new Date(date);
      result.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);

      return result;
    };
  }

  getDateShifts(date: Date): Shift[] {
    const day = Calendar.getStringDay(date.getDay());
    return this.shifts.filter((shift) => shift.day === day);
  }

  getOpening(date: Date): Date | null {
    const dayShiftsStart = this.getDateShifts(date)
      .filter(Calendar.isShiftValid)
      .map((shift) => shift.start)
      .filter(Boolean);

    if (dayShiftsStart.length === 0) return null;

    const firstStart = dayShiftsStart[0];
    return Calendar.timeToDateConverter(date)(firstStart);
  }

  getClosing(date: Date): Date | null {
    const dayShiftsEnd = this.getDateShifts(date)
      .filter(Calendar.isShiftValid)
      .map((shift) => shift.end)
      .filter(Boolean);
    if (dayShiftsEnd.length === 0) return null;
    const lastEnd = last(dayShiftsEnd);
    return Calendar.timeToDateConverter(date)(lastEnd);
  }

  isOpenDay(date: Date): boolean {
    const start = this.getOpening(date);
    const end = this.getClosing(date);
    if (!start || !end) return false;
    return end.getTime() > start.getTime();
  }

  isAfternoon(date: Date): boolean {
    const shifts = this.getDateShifts(date);
    if (shifts.length < 2) return false;
    if (!Calendar.isShiftValid(shifts[1])) return false;
    const afternoonStart = Calendar.timeToDateConverter(date)(shifts[1].start);
    if (!afternoonStart) return false;
    return isAfter(date, afternoonStart) || isEqual(afternoonStart, date);
  }

  shiftsToTimeMatrix(shifts: Shift[]): Array<0 | 1> {
    const timeToDateConverter = Calendar.timeToDateConverter(new Date());
    const step = this.step;
    if (!shifts || shifts.length === 0) return [];
    const firstShift = shifts[0];
    const lastShift = last(shifts);

    if (!firstShift || !lastShift) return [];

    const from = timeToDateConverter(firstShift.start);
    const to = timeToDateConverter(lastShift.end);

    if (!from || !to) return [];

    return shifts.reduce(function reduceShifts(
      timeMatrix,
      shift,
      index,
      shifts
    ) {
      const from = timeToDateConverter(shift.start);
      const to = timeToDateConverter(shift.end);

      if (!from || !to) return timeMatrix;

      if (index > 0 && shifts[index - 1]) {
        const closeFrom = timeToDateConverter(shifts[index - 1].end);
        const closeTo = timeToDateConverter(shift.start);

        if (closeFrom && closeTo && closeFrom.getTime() < closeTo.getTime()) {
          timeMatrix.push(
            ...new Array(differenceInMinutes(closeTo, closeFrom) / step).fill(0)
          );
        }
      }

      timeMatrix.push(
        ...new Array(differenceInMinutes(to, from) / step).fill(1)
      );

      return timeMatrix;
    },
    [] as Array<1 | 0>);
  }

  getTimeMatrix(): TimeMatrix {
    const shiftsByDay = groupBy(
      this.shifts.filter(Calendar.isShiftValid),
      "day"
    );

    return Calendar.DAY.reduce(
      (timeMatrix, day) =>
        ({
          ...timeMatrix,
          [day]: this.shiftsToTimeMatrix(shiftsByDay[day]),
        } as TimeMatrix),
      {} as TimeMatrix
    );
  }
}
