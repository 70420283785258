import React, { useState } from "react";
import {
  Box,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import { Template } from "../../graphql/resolvers";
import {
  TemplatesDocument,
  useDeleteTemplateMutation,
  useRenameTemplateMutation,
} from "../../graphql/operations";
import styled from "styled-components";

const Form = styled.form`
  width: 100%;
`;

function TemplateItem({
  template,
}: {
  template: Pick<Template, "id" | "name">;
}) {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(template.name);
  const [renameTemplate] = useRenameTemplateMutation({
    refetchQueries: [{ query: TemplatesDocument }],
  });
  const [deleteTemplate] = useDeleteTemplateMutation({
    refetchQueries: [{ query: TemplatesDocument }],
  });

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    renameTemplate({ variables: { id: template.id, name: value } }).then(() => {
      setEditMode(false);
    });
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  function handleDelete() {
    return deleteTemplate({ variables: { id: template.id } });
  }

  return (
    <ListItem dense={true} divider={true}>
      {editMode ? (
        <Form onSubmit={handleSubmit}>
          <ListItemText key={template.id}>
            <TextField
              fullWidth={true}
              type="text"
              label="Nom"
              value={value}
              onChange={handleChange}
            />
          </ListItemText>
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="validate" type="submit">
              <CheckIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="cancel"
              onClick={() => setEditMode(false)}
              type="button"
            >
              <CancelIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </Form>
      ) : (
        <>
          <ListItemText>
            <Box mt={2} p="4px 0 5px">
              <Typography variant="body1" onClick={() => setEditMode(true)}>
                {template.name}
              </Typography>
            </Box>
          </ListItemText>
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="rename"
              type="button"
              onClick={() => setEditMode(true)}
            >
              <EditTwoToneIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              type="button"
              onClick={handleDelete}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </>
      )}
    </ListItem>
  );
}

export default TemplateItem;
