import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type ApplyTemplateDto = {
  templateId: Scalars['ID'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type Auth = {
  __typename?: 'Auth';
  access_token: Scalars['String'];
  user: User;
};

export type Business = {
  __typename?: 'Business';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressLevel1?: Maybe<Scalars['String']>;
  addressLevel2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  professions: Array<Profession>;
  employees: Array<Employee>;
};

export type CreateEmployeeDto = {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  professionId: Scalars['ID'];
  color: Scalars['String'];
  contractHours?: Maybe<Scalars['Float']>;
};

export type CreateEventDto = {
  id: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  employeeId: Scalars['ID'];
};

export type CreateHolidayDto = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  employeeId: Scalars['ID'];
};

export type CreateProfessionDto = {
  name: Scalars['String'];
  color: Scalars['String'];
  /** Default to the logged-in user business */
  businessId?: Maybe<Scalars['String']>;
};

export type CreateTemplateDto = {
  name: Scalars['String'];
  events: Array<CreateTemplateEvent>;
};

export type CreateTemplateEvent = {
  /** Representing hours:minutes */
  start: Scalars['String'];
  /** Representing hours:minutes */
  end: Scalars['String'];
  day: Day;
  employeeId: Scalars['ID'];
};

export type CreateUserDto = {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};


export enum Day {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export type EditProfessionDto = {
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
};

export type Employee = {
  __typename?: 'Employee';
  id: Scalars['ID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  profession: Profession;
  color: Scalars['String'];
  order: Scalars['Int'];
  contractHours: Scalars['Int'];
  business: Business;
  hours: Scalars['Float'];
  holidays: Array<Holiday>;
};


export type EmployeeHoursArgs = {
  inputs: HoursDto;
};


export type EmployeeHolidaysArgs = {
  inputs: HolidaysDto;
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  start: Scalars['DateTime'];
  duration: Scalars['Float'];
  end: Scalars['DateTime'];
  employee: Employee;
};

export type FindEventsDto = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  employeeId?: Maybe<Scalars['ID']>;
};

export type Holiday = {
  __typename?: 'Holiday';
  id: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  employeeId: Scalars['ID'];
};

export type HolidaysDto = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type HoursDto = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type LoginDto = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  updateMyBusiness: Business;
  createUser: User;
  updateShift: Shift;
  createProfession: Profession;
  editProfession: Profession;
  removeProfession: Scalars['Boolean'];
  createEmployee: Employee;
  removeEmployee: Employee;
  updateEmployee: Employee;
  updateEmployeesOrder: Array<Employee>;
  createEvent: Event;
  removeEvent: Event;
  removeEvents: Array<Event>;
  createHoliday: Holiday;
  updateHoliday: Holiday;
  deleteHoliday: Holiday;
  login: Auth;
  register: Auth;
  createTemplate: Template;
  deleteTemplate: Template;
  updateTemplate: Template;
  applyTemplate: Scalars['Boolean'];
};


export type MutationUpdateMyBusinessArgs = {
  inputs: UpdateBusinessDto;
};


export type MutationCreateUserArgs = {
  inputs: CreateUserDto;
};


export type MutationUpdateShiftArgs = {
  inputs: UpdateShiftDto;
};


export type MutationCreateProfessionArgs = {
  inputs: CreateProfessionDto;
};


export type MutationEditProfessionArgs = {
  inputs: EditProfessionDto;
};


export type MutationRemoveProfessionArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEmployeeArgs = {
  inputs: CreateEmployeeDto;
};


export type MutationRemoveEmployeeArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateEmployeeArgs = {
  inputs: UpdateEmployeDto;
};


export type MutationUpdateEmployeesOrderArgs = {
  employeesId: Array<Scalars['ID']>;
};


export type MutationCreateEventArgs = {
  inputs: CreateEventDto;
};


export type MutationRemoveEventArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveEventsArgs = {
  inputs: FindEventsDto;
};


export type MutationCreateHolidayArgs = {
  inputs: CreateHolidayDto;
};


export type MutationUpdateHolidayArgs = {
  inputs: UpdateHolidayDto;
};


export type MutationDeleteHolidayArgs = {
  id: Scalars['ID'];
};


export type MutationLoginArgs = {
  inputs: LoginDto;
};


export type MutationRegisterArgs = {
  inputs: RegisterDto;
};


export type MutationCreateTemplateArgs = {
  inputs: CreateTemplateDto;
};


export type MutationDeleteTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateTemplateArgs = {
  inputs: UpdateTemplateDto;
};


export type MutationApplyTemplateArgs = {
  inputs: ApplyTemplateDto;
};

export type Profession = {
  __typename?: 'Profession';
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  businesses: Array<Business>;
  business: Business;
  myBusiness: Business;
  me: User;
  shifts: Array<Shift>;
  professions: Array<Profession>;
  employees: Array<Employee>;
  employee: Employee;
  events: Array<Event>;
  holidays: Holiday;
  templates: Array<Template>;
};


export type QueryMyBusinessArgs = {
  ownerId: Scalars['String'];
};


export type QueryShiftsArgs = {
  day?: Maybe<Scalars['String']>;
};


export type QueryEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryEventsArgs = {
  data: FindEventsDto;
};

export type RegisterDto = {
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  password: Scalars['String'];
};

export enum Role {
  Admin = 'ADMIN',
  Owner = 'OWNER',
  Manager = 'MANAGER',
  Employee = 'EMPLOYEE',
  ScheduleUpdate = 'SCHEDULE_UPDATE',
  EmployeeUpdate = 'EMPLOYEE_UPDATE',
  EmployeeCreate = 'EMPLOYEE_CREATE'
}

export type Shift = {
  __typename?: 'Shift';
  id: Scalars['ID'];
  /** Representing hours:minutes */
  start?: Maybe<Scalars['String']>;
  /** Representing hours:minutes */
  end?: Maybe<Scalars['String']>;
  day: Day;
};

export type Template = {
  __typename?: 'Template';
  id: Scalars['ID'];
  name: Scalars['String'];
  events: Array<TemplateEvent>;
};

export type TemplateEvent = {
  __typename?: 'TemplateEvent';
  /** Representing hours:minutes */
  start: Scalars['String'];
  /** Representing hours:minutes */
  end: Scalars['String'];
  day: Day;
  employeeId: Scalars['ID'];
};

export type UpdateBusinessDto = {
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressLevel1?: Maybe<Scalars['String']>;
  addressLevel2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type UpdateEmployeDto = {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  professionId: Scalars['ID'];
  color: Scalars['String'];
  contractHours?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
};

export type UpdateHolidayDto = {
  id: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type UpdateShiftDto = {
  id: Scalars['ID'];
  /** Representing hours:minutes */
  start?: Maybe<Scalars['String']>;
  /** Representing hours:minutes */
  end?: Maybe<Scalars['String']>;
};

export type UpdateTemplateDto = {
  name?: Maybe<Scalars['String']>;
  events?: Maybe<Array<CreateTemplateEvent>>;
  id: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  roles: Array<Role>;
  business?: Maybe<Business>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}> = (obj: T, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Query: ResolverTypeWrapper<{}>;
  Business: ResolverTypeWrapper<Business>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  String: ResolverTypeWrapper<Scalars['String']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  Profession: ResolverTypeWrapper<Profession>;
  Employee: ResolverTypeWrapper<Employee>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  HoursDto: HoursDto;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  HolidaysDto: HolidaysDto;
  Holiday: ResolverTypeWrapper<Holiday>;
  User: ResolverTypeWrapper<User>;
  Role: Role;
  Shift: ResolverTypeWrapper<Shift>;
  Day: Day;
  FindEventsDto: FindEventsDto;
  Event: ResolverTypeWrapper<Event>;
  Template: ResolverTypeWrapper<Template>;
  TemplateEvent: ResolverTypeWrapper<TemplateEvent>;
  Mutation: ResolverTypeWrapper<{}>;
  UpdateBusinessDto: UpdateBusinessDto;
  CreateUserDto: CreateUserDto;
  UpdateShiftDto: UpdateShiftDto;
  CreateProfessionDto: CreateProfessionDto;
  EditProfessionDto: EditProfessionDto;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  CreateEmployeeDto: CreateEmployeeDto;
  UpdateEmployeDto: UpdateEmployeDto;
  CreateEventDto: CreateEventDto;
  CreateHolidayDto: CreateHolidayDto;
  UpdateHolidayDto: UpdateHolidayDto;
  LoginDto: LoginDto;
  Auth: ResolverTypeWrapper<Auth>;
  RegisterDto: RegisterDto;
  CreateTemplateDto: CreateTemplateDto;
  CreateTemplateEvent: CreateTemplateEvent;
  UpdateTemplateDto: UpdateTemplateDto;
  ApplyTemplateDto: ApplyTemplateDto;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Query: {};
  Business: Business;
  ID: Scalars['ID'];
  String: Scalars['String'];
  DateTime: Scalars['DateTime'];
  Profession: Profession;
  Employee: Employee;
  Int: Scalars['Int'];
  HoursDto: HoursDto;
  Float: Scalars['Float'];
  HolidaysDto: HolidaysDto;
  Holiday: Holiday;
  User: User;
  Shift: Shift;
  FindEventsDto: FindEventsDto;
  Event: Event;
  Template: Template;
  TemplateEvent: TemplateEvent;
  Mutation: {};
  UpdateBusinessDto: UpdateBusinessDto;
  CreateUserDto: CreateUserDto;
  UpdateShiftDto: UpdateShiftDto;
  CreateProfessionDto: CreateProfessionDto;
  EditProfessionDto: EditProfessionDto;
  Boolean: Scalars['Boolean'];
  CreateEmployeeDto: CreateEmployeeDto;
  UpdateEmployeDto: UpdateEmployeDto;
  CreateEventDto: CreateEventDto;
  CreateHolidayDto: CreateHolidayDto;
  UpdateHolidayDto: UpdateHolidayDto;
  LoginDto: LoginDto;
  Auth: Auth;
  RegisterDto: RegisterDto;
  CreateTemplateDto: CreateTemplateDto;
  CreateTemplateEvent: CreateTemplateEvent;
  UpdateTemplateDto: UpdateTemplateDto;
  ApplyTemplateDto: ApplyTemplateDto;
}>;

export type AuthResolvers<ContextType = any, ParentType extends ResolversParentTypes['Auth'] = ResolversParentTypes['Auth']> = ResolversObject<{
  access_token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
}>;

export type BusinessResolvers<ContextType = any, ParentType extends ResolversParentTypes['Business'] = ResolversParentTypes['Business']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  addressLevel1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  addressLevel2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  professions?: Resolver<Array<ResolversTypes['Profession']>, ParentType, ContextType>;
  employees?: Resolver<Array<ResolversTypes['Employee']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
}>;

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type EmployeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Employee'] = ResolversParentTypes['Employee']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profession?: Resolver<ResolversTypes['Profession'], ParentType, ContextType>;
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  contractHours?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  business?: Resolver<ResolversTypes['Business'], ParentType, ContextType>;
  hours?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<EmployeeHoursArgs, 'inputs'>>;
  holidays?: Resolver<Array<ResolversTypes['Holiday']>, ParentType, ContextType, RequireFields<EmployeeHolidaysArgs, 'inputs'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
}>;

export type EventResolvers<ContextType = any, ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  employee?: Resolver<ResolversTypes['Employee'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
}>;

export type HolidayResolvers<ContextType = any, ParentType extends ResolversParentTypes['Holiday'] = ResolversParentTypes['Holiday']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  employeeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
}>;

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  updateMyBusiness?: Resolver<ResolversTypes['Business'], ParentType, ContextType, RequireFields<MutationUpdateMyBusinessArgs, 'inputs'>>;
  createUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'inputs'>>;
  updateShift?: Resolver<ResolversTypes['Shift'], ParentType, ContextType, RequireFields<MutationUpdateShiftArgs, 'inputs'>>;
  createProfession?: Resolver<ResolversTypes['Profession'], ParentType, ContextType, RequireFields<MutationCreateProfessionArgs, 'inputs'>>;
  editProfession?: Resolver<ResolversTypes['Profession'], ParentType, ContextType, RequireFields<MutationEditProfessionArgs, 'inputs'>>;
  removeProfession?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveProfessionArgs, 'id'>>;
  createEmployee?: Resolver<ResolversTypes['Employee'], ParentType, ContextType, RequireFields<MutationCreateEmployeeArgs, 'inputs'>>;
  removeEmployee?: Resolver<ResolversTypes['Employee'], ParentType, ContextType, RequireFields<MutationRemoveEmployeeArgs, 'id'>>;
  updateEmployee?: Resolver<ResolversTypes['Employee'], ParentType, ContextType, RequireFields<MutationUpdateEmployeeArgs, 'inputs'>>;
  updateEmployeesOrder?: Resolver<Array<ResolversTypes['Employee']>, ParentType, ContextType, RequireFields<MutationUpdateEmployeesOrderArgs, 'employeesId'>>;
  createEvent?: Resolver<ResolversTypes['Event'], ParentType, ContextType, RequireFields<MutationCreateEventArgs, 'inputs'>>;
  removeEvent?: Resolver<ResolversTypes['Event'], ParentType, ContextType, RequireFields<MutationRemoveEventArgs, 'id'>>;
  removeEvents?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType, RequireFields<MutationRemoveEventsArgs, 'inputs'>>;
  createHoliday?: Resolver<ResolversTypes['Holiday'], ParentType, ContextType, RequireFields<MutationCreateHolidayArgs, 'inputs'>>;
  updateHoliday?: Resolver<ResolversTypes['Holiday'], ParentType, ContextType, RequireFields<MutationUpdateHolidayArgs, 'inputs'>>;
  deleteHoliday?: Resolver<ResolversTypes['Holiday'], ParentType, ContextType, RequireFields<MutationDeleteHolidayArgs, 'id'>>;
  login?: Resolver<ResolversTypes['Auth'], ParentType, ContextType, RequireFields<MutationLoginArgs, 'inputs'>>;
  register?: Resolver<ResolversTypes['Auth'], ParentType, ContextType, RequireFields<MutationRegisterArgs, 'inputs'>>;
  createTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationCreateTemplateArgs, 'inputs'>>;
  deleteTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationDeleteTemplateArgs, 'id'>>;
  updateTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationUpdateTemplateArgs, 'inputs'>>;
  applyTemplate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationApplyTemplateArgs, 'inputs'>>;
}>;

export type ProfessionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Profession'] = ResolversParentTypes['Profession']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
}>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  businesses?: Resolver<Array<ResolversTypes['Business']>, ParentType, ContextType>;
  business?: Resolver<ResolversTypes['Business'], ParentType, ContextType>;
  myBusiness?: Resolver<ResolversTypes['Business'], ParentType, ContextType, RequireFields<QueryMyBusinessArgs, 'ownerId'>>;
  me?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  shifts?: Resolver<Array<ResolversTypes['Shift']>, ParentType, ContextType, RequireFields<QueryShiftsArgs, never>>;
  professions?: Resolver<Array<ResolversTypes['Profession']>, ParentType, ContextType>;
  employees?: Resolver<Array<ResolversTypes['Employee']>, ParentType, ContextType>;
  employee?: Resolver<ResolversTypes['Employee'], ParentType, ContextType, RequireFields<QueryEmployeeArgs, 'id'>>;
  events?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType, RequireFields<QueryEventsArgs, 'data'>>;
  holidays?: Resolver<ResolversTypes['Holiday'], ParentType, ContextType>;
  templates?: Resolver<Array<ResolversTypes['Template']>, ParentType, ContextType>;
}>;

export type ShiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['Shift'] = ResolversParentTypes['Shift']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  day?: Resolver<ResolversTypes['Day'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
}>;

export type TemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Template'] = ResolversParentTypes['Template']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  events?: Resolver<Array<ResolversTypes['TemplateEvent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
}>;

export type TemplateEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateEvent'] = ResolversParentTypes['TemplateEvent']> = ResolversObject<{
  start?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  end?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['Day'], ParentType, ContextType>;
  employeeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
}>;

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['Role']>, ParentType, ContextType>;
  business?: Resolver<Maybe<ResolversTypes['Business']>, ParentType, ContextType>;
  confirmedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  Auth?: AuthResolvers<ContextType>;
  Business?: BusinessResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Employee?: EmployeeResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  Holiday?: HolidayResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Profession?: ProfessionResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Shift?: ShiftResolvers<ContextType>;
  Template?: TemplateResolvers<ContextType>;
  TemplateEvent?: TemplateEventResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
}>;


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
