import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { client } from "../apollo/client";

interface AppContext {
  loading: boolean;
  isLoggedIn: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  logout: () => void;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppContext = createContext<AppContext>({
  loading: true,
  isLoggedIn: false,
  setLoading: () => {},
  logout: () => {},
  setIsLoggedIn: () => {},
});

const AppState: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    window.dataLayer.push({ userId: undefined });
    return client.resetStore();
  }, []);

  useEffect(() => {
    setIsLoggedIn(Boolean(localStorage.getItem("token")));
  }, []);

  return (
    <AppContext.Provider
      value={{ loading, setLoading, logout, isLoggedIn, setIsLoggedIn }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const LoadingHandler: React.FC<{ loading: boolean }> = ({
  loading: loadingProp,
}) => {
  const { setLoading } = useContext(AppContext);

  useEffect(() => {
    setLoading(loadingProp);
  }, [setLoading, loadingProp]);

  return null;
};

export const useAppState = () => {
  const { loading, logout, isLoggedIn, setIsLoggedIn } = useContext(AppContext);

  return {
    loading,
    logout,
    isLoggedIn,
    setIsLoggedIn,
  };
};

export default AppState;
