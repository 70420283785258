import React, { lazy, Suspense } from "react";
import "./App.css";

import { ApolloProvider } from "@apollo/react-hooks";
import { client } from "./apollo/client";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Theme from "./components/Theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import AppState from "./components/AppState";
import Layout from "./components/Layout";
import ProtectedComponent from "./components/Protected/Protected.component";
import WelcomeModal from "./components/WelcomeModal/WelcomeModal";
import SchedulerPrintable from "./pages/SchedulerPrintable";
import Templates from "./pages/Templates";

const SchedulerPage = lazy(() => import("./pages/Scheduler"));
const LoginPage = lazy(() => import("./pages/Login"));
const RegisterPage = lazy(() => import("./pages/Register"));
const SettingsPage = lazy(() => import("./pages/Settings"));
const BusinessPage = lazy(() => import("./pages/Business"));
const FunctionsPage = lazy(() => import("./pages/Professions"));
const EmployeesPage = lazy(() => import("./pages/Employees"));
const EmployeeEdit = lazy(() => import("./pages/EmployeeEdit"));
const EmployeeCreate = lazy(() => import("./pages/EmployeeCreate"));

const App: React.FC = () => {
  return (
    <AppState>
      <Theme>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
          <ApolloProvider client={client}>
            <Router>
              <Suspense fallback={<Layout loading={true}>{""}</Layout>}>
                <Switch>
                  <Route path="/register">
                    <RegisterPage />
                  </Route>
                  <Route path="/login">
                    <LoginPage />
                  </Route>
                  <Route path="/scheduler/print">
                    <ProtectedComponent>
                      <SchedulerPrintable />
                    </ProtectedComponent>
                  </Route>
                  <Route path="/scheduler">
                    <ProtectedComponent>
                      <>
                        <WelcomeModal />
                        <SchedulerPage />
                      </>
                    </ProtectedComponent>
                  </Route>
                  <Route path="/business">
                    <ProtectedComponent>
                      <>
                        <WelcomeModal />
                        <BusinessPage />
                      </>
                    </ProtectedComponent>
                  </Route>
                  <Route path="/settings">
                    <ProtectedComponent>
                      <SettingsPage />
                    </ProtectedComponent>
                  </Route>
                  <Route path="/functions">
                    <ProtectedComponent>
                      <FunctionsPage />
                    </ProtectedComponent>
                  </Route>
                  <Route path="/employee/create">
                    <ProtectedComponent>
                      <EmployeeCreate />
                    </ProtectedComponent>
                  </Route>
                  <Route path="/employees/:id/edit">
                    <ProtectedComponent>
                      <EmployeeEdit />
                    </ProtectedComponent>
                  </Route>
                  <Route path="/employees">
                    <ProtectedComponent>
                      <>
                        <WelcomeModal />
                        <EmployeesPage />
                      </>
                    </ProtectedComponent>
                  </Route>
                  <Route path="/templates">
                    <ProtectedComponent>
                      <>
                        <Templates />
                      </>
                    </ProtectedComponent>
                  </Route>
                  <Route path="/">
                    <ProtectedComponent>
                      <>
                        <WelcomeModal />
                        <SchedulerPage />
                      </>
                    </ProtectedComponent>
                  </Route>
                </Switch>
              </Suspense>
            </Router>
            <ToastContainer />
          </ApolloProvider>
        </MuiPickersUtilsProvider>
      </Theme>
    </AppState>
  );
};

export default App;
