import React from "react";
import { useWelcomeQuery, WelcomeQuery } from "../../graphql/operations";
import { Paper, Modal, Box, Link } from "@material-ui/core";
import {
  SpacingProps,
  spacing,
  SizingProps,
  sizing,
} from "@material-ui/system";
import styled from "styled-components";

import { Link as RouterLink, useLocation } from "react-router-dom";

const Wrapper = styled(Paper).withConfig({
  shouldForwardProp: (prop) => !["maxWidth"].includes(prop),
})<SpacingProps & SizingProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${sizing};
  ${spacing};
`;

function stepDescription(data: WelcomeQuery): React.ReactNode {
  if (!data.me.business || !data.me.business.name) {
    return (
      <>
        <h2>Bienvenue {data.me.firstname}</h2>
        <p>Avant de commencer il faut paramétrer votre Pharmacie.</p>
      </>
    );
  }
  if (data.me.business.employees.length === 0) {
    return (
      <>
        <h2>Voila</h2>
        <p>Il ne vous reste plus qu'a ajouter vos employé(e)s.</p>
      </>
    );
  }
  return "";
}

function nextStepLink(data: WelcomeQuery): string {
  if (!data.me.business || !data.me.business.name) {
    return "/business";
  }
  if (data.me.business.employees.length === 0) {
    return "/employee/create";
  }
  return "";
}

export default function WelcomeModal() {
  const { data } = useWelcomeQuery();
  const location = useLocation();

  // If we already have employee we won't show the modal.
  if (!data || (data.me.business && data.me.business.employees.length > 0))
    return null;
  // If no business and on business page
  if (
    (!data.me.business || !data.me.business.name) &&
    location.pathname === "/business"
  )
    return null;
  // If no fonctions and on functions page
  if (
    data.me.business &&
    data.me.business.professions.length === 0 &&
    location.pathname === "/functions"
  )
    return null;
  // If no employee and on employee page
  if (
    data.me.business &&
    data.me.business.employees &&
    location.pathname.startsWith("/employee")
  )
    return null;
  return (
    <Modal
      open
      aria-labelledby="Bienvenu guide"
      aria-describedby="Étapes à suivre"
    >
      <Wrapper padding={4} maxWidth="sm">
        {stepDescription(data)}
        <Box textAlign="right">
          <Link
            href={nextStepLink(data)}
            color="primary"
            variant="button"
            to={nextStepLink(data)}
            component={RouterLink}
          >
            Par ici
          </Link>
        </Box>
      </Wrapper>
    </Modal>
  );
}
