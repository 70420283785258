import React from "react";
import styled from "styled-components";
import logo from "../assets/logo.svg";

import Box from "@material-ui/core/Box";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";

import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import { useAppState } from "./AppState";
import Hidden from "@material-ui/core/Hidden";

interface Props {
  handleBurgerButton?: () => void;
  showBurgerButton?: boolean;
  fixed: boolean;
}

const Logo = styled.img`
  margin-bottom: 0;
  height: 70px;
  width: auto;
`;

const Bar = styled(AppBar)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1} !important;
`;

const Burger = styled(IconButton)``;

const Header: React.FC<Props> = ({
  handleBurgerButton,
  showBurgerButton = false,
  fixed = true,
}) => {
  const location = useLocation();
  const { logout, isLoggedIn } = useAppState();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  const theme = useTheme();

  return (
    <Bar position={fixed ? "fixed" : "relative"} theme={theme}>
      <Toolbar>
        <Hidden smUp={!showBurgerButton} implementation="css">
          <Burger
            onClick={() => handleBurgerButton && handleBurgerButton()}
            theme={theme}
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </Burger>
        </Hidden>
        <Box flexGrow={1}>
          <Link to="/">
            <Logo src={logo} alt="Pharmille logo" />
          </Link>
        </Box>
        {isLoggedIn ? (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Se Déconnecter</MenuItem>
            </Menu>
          </div>
        ) : (
          !["/login", "/register"].includes(location.pathname) && (
            <Button color="inherit" component={Link} to="/login">
              Se Connecter
            </Button>
          )
        )}
      </Toolbar>
    </Bar>
  );
};

export default Header;
