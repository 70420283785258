import React from "react";

import { toast } from "react-toastify";

class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    if (window.ga) {
      window.ga("send", "exception", {
        exDescription: error.message,
        exFatal: true
      });
    }
  }

  render() {
    if (this.state.hasError) {
      toast.error(
        "Oups. Une erreur inattendue vient de se produire. Nous allons tous faire pour la corriger."
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
