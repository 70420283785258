import React from "react";
import styled from "styled-components";

import Header from "./Header";

import { useHistory } from "react-router-dom";

import Drawer from "@material-ui/core/Drawer";
import LinearProgress from "@material-ui/core/LinearProgress";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LocalPharmacyIcon from "@material-ui/icons/LocalPharmacy";
import PeopleIcon from "@material-ui/icons/People";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { useTheme } from "@material-ui/core/styles";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import RepeatIcon from "@material-ui/icons/Repeat";
import { History } from "history";

import ErrorBoundary from "./ErrorBoundary";
import { useAppState } from "./AppState";
import { Box } from "@material-ui/core";

interface Props {
  children: React.ReactNode;
  loading?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
}

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: ${drawerWidth}px;
  }
`;

const Navigation = styled.nav`
  .MuiDrawer-paper {
    width: ${drawerWidth}px;
    flex-shrink: 0;
    padding-top: 78px;
  }
`;

const Content = styled.div<{
  isLoggedIn: boolean;
  fullWidth: boolean;
  fullHeight: boolean;
}>`
  ${({ fullHeight }) => !fullHeight && `margin-top: 73px`};

  ${({ theme, isLoggedIn, fullWidth }) =>
    isLoggedIn &&
    !fullWidth &&
    `
    ${theme.breakpoints.up("sm")} {
      width: calc(100% - ${drawerWidth}px);
      margin-left: ${drawerWidth}px;
    }
  `}
`;

const drawer = (history: History) => (
  <>
    <List>
      <ListItem button onClick={() => history.push("/scheduler")}>
        <ListItemIcon>
          <CalendarTodayIcon />
        </ListItemIcon>
        <ListItemText primary="Emploi du temps" />
      </ListItem>
      <ListItem button onClick={() => history.push("/templates")}>
        <ListItemIcon>
          <RepeatIcon />
        </ListItemIcon>
        <ListItemText primary="Mes Modèles" />
      </ListItem>
      <ListItem button onClick={() => history.push("/business")}>
        <ListItemIcon>
          <LocalPharmacyIcon />
        </ListItemIcon>
        <ListItemText primary="Entreprise" />
      </ListItem>
      <ListItem button onClick={() => history.push("/functions")}>
        <ListItemIcon>
          <AssignmentIndIcon />
        </ListItemIcon>
        <ListItemText primary="Fonctions" />
      </ListItem>
      <ListItem button onClick={() => history.push("/employees")}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Employé(e)s" />
      </ListItem>
    </List>
  </>
);

const Loading = () => {
  const { loading } = useAppState();
  return <>{loading ? <LinearProgress /> : <Box height={4} />}</>;
};

const Layout: React.FC<Props> = ({
  fullHeight = false,
  fullWidth = false,
  children,
}) => {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const history = useHistory();
  const { isLoggedIn } = useAppState();

  return (
    <>
      {isLoggedIn && (
        <Navigation aria-label="menu">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp={!fullWidth} implementation="css">
            <StyledDrawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer(history)}
            </StyledDrawer>
          </Hidden>
          <Hidden xsDown xsUp={fullWidth} implementation="css">
            <StyledDrawer variant="permanent" open>
              {drawer(history)}
            </StyledDrawer>
          </Hidden>
        </Navigation>
      )}
      <Header
        showBurgerButton={fullWidth}
        handleBurgerButton={handleDrawerToggle}
        fixed={!fullHeight}
      />
      <Content
        fullHeight={fullHeight}
        fullWidth={fullWidth}
        isLoggedIn={isLoggedIn}
        theme={theme}
      >
        <Loading />
        <ErrorBoundary>{children}</ErrorBoundary>
      </Content>
    </>
  );
};

export default Layout;
