import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { CircularProgress, List, Typography } from "@material-ui/core";
import Layout from "../components/Layout";
import React from "react";
import { useTemplatesQuery } from "../graphql/operations";
import TemplateItem from "../components/TemplateItem/TemplateItem.component";

function Templates() {
  const { data, loading } = useTemplatesQuery();

  return (
    <Layout>
      <Container maxWidth="md">
        <Box paddingTop={2}>
          <Typography component="h1" variant="h4">
            Mes Modèles
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box mt={2}>
              <List>
                {data
                  ? data.templates.map((template) => (
                      <TemplateItem key={template.id} template={template} />
                    ))
                  : 'Pas de modèles ? Créez vos modèle à partir d\'une semaine dans "Emploi du temps".'}
              </List>
            </Box>
          )}
        </Box>
      </Container>
    </Layout>
  );
}

export default Templates;
